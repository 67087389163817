<script lang="ts" setup>
const props = defineProps<{
  label: string;
  clickTarget?: string;
  preventClick?: boolean;
}>()

/**
 * Is invoked when the input group label is clicked
 * @param {MouseEvent} e
 */
const handleClick = (e: MouseEvent) => {
  if (props.preventClick) {
    e.preventDefault()
  }
  if (props.clickTarget) {
    document.getElementById(props.clickTarget)?.focus()
  }
}
</script>

<template>
  <label class="block" @click="($event) => handleClick($event)">
    <span class="text-secondary-lighter text-sm">{{ label }}</span>
    <slot></slot>
  </label>
</template>