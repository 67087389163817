<script lang="ts" setup>
const props = defineProps<{
  item?: {
    title: string;
    prop: string;
    sortable: boolean;
  };
  sortBy?: string;
  sortDesc?: boolean;
}>();

const emit = defineEmits<{
  (e: "changeSorting", payload: { prop: string; sortDesc: boolean }): void;
}>();

/**
 * @param {string} prop
 * @fires changeSorting
 */
const setSorting = (prop: string) => {
  if (prop === props.sortBy)
    emit("changeSorting", { prop: prop, sortDesc: !props.sortDesc });
  else
    emit("changeSorting", { prop: prop, sortDesc: props.sortDesc as boolean });
};
</script>
<template>
  <th
    scope="col"
    class="py-3.5 px-3 text-left text-sm font-semibold text-secondary"
  >
    <template v-if="item">
      <span
        class="group inline-flex items-center cursor-pointer"
        v-if="item.sortable"
        @click="item ? setSorting(item.prop) : null"
      >
        {{ item.title }}
        <span
          class="ml-2 flex-none rounded text-gray-darker"
          :class="
            sortBy === item.prop
              ? 'visible !text-primary'
              : 'invisible group-hover:visible group-focus:visible'
          "
        >
          <span v-if="sortDesc" class="icon-chevron-down-sm h-4 w-4" aria-hidden="true"></span>
          <span v-else class="icon-chevron-up-sm h-4 w-4" aria-hidden="true"></span>
        </span>
      </span>
      <template v-else>{{ item.title }}</template>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </th>
</template>
