<script setup lang="ts">
import { RouterView } from 'vue-router';
import axios from 'axios';
import { useMsal } from './composition-api/useMsal';
import { loginRequest } from './authConfig';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { onMounted, ref } from 'vue';
import { useUsersStore } from './stores/user';
import { useIsAuthenticated } from "./composition-api/useIsAuthenticated";
import Alert from '@/components/AlertComp.vue'
import { useTimeoutsStore } from './stores/timeouts';
import SpinnerComp from './components/SpinnerComp.vue';

const timeoutStore = useTimeoutsStore()
const store = useUsersStore()
const { instance, inProgress } = useMsal()
const isLoaded = ref(false)
const isAuthenticated = useIsAuthenticated();

/** A collection of IDs associated with getting permissions timing out */
const permissionTimeoutIds = ref<{ids: string[]} | null>(null)
/** A collection of IDs associated with getting an access token timing out */
const tokenTimeoutIds = ref<{ids: string[]} | null>(null)

onMounted(async () => {
  if (isAuthenticated.value) {
    permissionTimeoutIds.value = timeoutStore.addTimeouts({}, {timeout: 10000}, 'hente dine tilladelser', '', true)
    store.getPermissions().then(() => {
      if (permissionTimeoutIds.value) {
        timeoutStore.removeTimeouts(permissionTimeoutIds.value.ids)
      }
      isLoaded.value = true
    }).catch(error => {
      if (permissionTimeoutIds.value) {
        timeoutStore.removeTimeouts(permissionTimeoutIds.value.ids)
      }
      console.debug(error)
      isLoaded.value = true
    })
  } else {
    isLoaded.value = true
  }
})

const delay = (delay: number) => {
  console.debug('adding delay')
  return new Promise((resolve) => setTimeout(() => {
    resolve(true)
  }, delay));
}

axios.interceptors.request.use(async (config: any) => {
  timeoutStore.removeAllTimeouts('token')
  tokenTimeoutIds.value = timeoutStore.addTimeouts({}, {timeout: 10000}, 'hente et ID token', 'token', true)
  const response = await instance.acquireTokenSilent({
    ...loginRequest
  }).then((response) => {
    if (tokenTimeoutIds.value) {
      timeoutStore.removeTimeouts(tokenTimeoutIds.value.ids)
    }
    return response
  }).catch(async (e) => {
    if (tokenTimeoutIds.value) {
      timeoutStore.removeTimeouts(tokenTimeoutIds.value.ids)
    }
    if (e instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect(loginRequest);
    }
    throw e;
  });
	if (inProgress.value === InteractionStatus.None) {
    if (config.add_delay) {
      await delay(config.add_delay)
    }
    if (response) {
      config.timeout = 15000
      config.headers.Authorization = 'bearer ' + response.accessToken
    }
    return config;
	}
});
</script>

<template>
  <div class="fixed z-50 left-1/2 -translate-x-1/2 bottom-4 flex gap-2 flex-col">
    <TransitionGroup name="fade">
      <div
        v-for="id of permissionTimeoutIds?.ids"
        :key="id"
        v-show="timeoutStore.getShowTimeout(id)"
      >
        <Alert
          :type="timeoutStore.getTimeoutType(id) || 'info'"
          :show="timeoutStore.getShowTimeout(id) || false"
          class="shadow-md !max-w-full !w-full"
        >
          {{ timeoutStore.getTimeoutMessage(id) }}
        </Alert>
      </div>
      <div
        v-for="id of tokenTimeoutIds?.ids"
        :key="id"
        v-show="timeoutStore.getShowTimeout(id)"
      >
        <Alert
          :type="timeoutStore.getTimeoutType(id) || 'info'"
          :show="timeoutStore.getShowTimeout(id) || false"
          class="shadow-md !max-w-full !w-full"
        >
          {{ timeoutStore.getTimeoutMessage(id) }}
        </Alert>
      </div>
    </TransitionGroup>
    <Alert
      v-for="alert of timeoutStore.timeouts.filter(t => !t.local)"
      :key="alert.id"
      :type="alert.type || 'info'"
      :show="(alert.show as boolean)"
      :class="alert.type"
      class="global-timeout-alert shadow-md !max-w-full !w-full"
    >{{ alert.message }}</Alert>
  </div>
  <RouterView v-if="isLoaded" />
  <div v-else class="flex items-center justify-center w-full h-[100vh]">
    <SpinnerComp :size="40"></SpinnerComp>
  </div>
</template>

<style scoped>
</style>
