import { ref } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios'
import type { DocumentType } from '@/types'
import { useTimeoutsStore } from './timeouts'

export const useTypesStore = defineStore('types', () => {
  const timeoutStore = useTimeoutsStore();
  const types = ref<DocumentType[]>([]);

  /**
   * Gets document type objects from the API
   * @param {boolean} [local=false]
   * @returns {Promise<boolean|Error>}
   */
  const getTypesFromApi = (local: boolean = false) => {
    return new Promise((resolve, reject) => {
      let timeouts: null | {ids: string[]}
      if (!local) {
        timeouts = timeoutStore.addTimeouts({}, {timeout: 10000}, 'hente dokumenttyperne', 'other')
      }
      axios.get('/api/v1/document-types').then(response => {
        if (!local && timeouts) {
          timeoutStore.removeTimeouts(timeouts.ids)
        }
        for (const type of response.data.list) {
          addType(type);
        }
        resolve(true);
      }).catch(error => {
        if (!local && timeouts) {
          timeoutStore.removeTimeouts(timeouts.ids)
        }
        reject(error);
      })
    }) 
  }

  /**
   * Adds a document type to the types variable
   * @param {DocumentType} type 
   * @returns {Promise<boolean>}
   */
  const addType = (type: DocumentType) => {
    return new Promise((resolve) => {
      if (types.value.find(t => t.id === type.id)) {
        types.value[types.value.findIndex((i) => i.id === type.id)] = type;
      } else {
        types.value.push(type)
      }
      resolve(true)
    })
  }

  return { types, addType, getTypesFromApi }
})
