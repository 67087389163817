import { ref } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios'

export const useEmailStore = defineStore('email', () => {
  const subject = ref('');
  const message = ref('');
  const template = ref<{subject: string; message: string;} | null>(null)

  /**
   * Gets the email template from the API
   * @returns {Promise<{subject: string, message: string}|Error>}
   */
  const getEmailTemplateFromApi = () => {
    return new Promise((resolve, reject) => {
      axios.get('/api/v1/mail/template').then((response) => {
        template.value = response.data
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  /**
   * Saves the email template to the API
   * @returns {Promise<{subject: string, message: string}|Error>}
   */
  const saveTemplate = () => {
    return new Promise((resolve, reject) => {
      axios.put('/api/v1/mail/template', template.value).then((response) => {
        template.value = response.data
        resolve(response.data)
      }).catch((error) => {
        reject(error)
      })
    })
  }

  return {template, getEmailTemplateFromApi, saveTemplate, subject, message }
})
