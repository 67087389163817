import { ref } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios';

export const useUsersStore = defineStore('users', () => {
  const permissions = ref<string[]>([]);

  /**
   * Checks if a permission is present in the permissions array
   * @param {string} permission 
   * @returns {boolean}
   */
  const checkPermission = (permission: string) => {
    return permissions.value.includes(permission);
  }

  /**
   * Adds a permission to the permissions array
   * @param {string} permission 
   */
  const addPermission = (permission: string) => {
    if (permissions.value.find(p => p === permission)) {
      permissions.value[permissions.value.findIndex((p) => p === permission)] = permission;
    } else {
      permissions.value.push(permission)
    }
  }

  /**
   * Gets the current users permissions from the API
   * @param {string=} token 
   * @returns {Promise<boolean|Error>}
   */
  const getPermissions = (token?: string) => {
    return new Promise((resolve, reject) => {
      let config = {}
      if (typeof token !== 'undefined') {
        config = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      }
      axios.get('/api/v1/permissions', config).then(response => {
        for (const permission of response.data) {
          addPermission(permission)
        }
      }).then(() => {
        resolve(true)
      }).catch(error => {
        reject(error)
      })
    })
  }

  return { permissions, checkPermission, addPermission, getPermissions }
})