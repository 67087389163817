<script setup lang="ts">
import Input from '@/components/InputComp.vue';
import Alert from '@/components/AlertComp.vue';
import { computed, onMounted, ref, inject, watch, onBeforeUnmount } from 'vue';
import { onBeforeRouteLeave, useRouter } from 'vue-router';
import type { SearchResponse, Document, ErrorResponse, DocumentType } from '@/types';
import PageWrap from '@/components/PageWrap.vue';
import { useDocumentsStore } from '@/stores/documents';
import SidebarGroup from '@/components/SidebarGroup.vue';
import CheckboxGroup from '@/components/CheckboxGroup.vue';
import Button from '@/components/ButtonComp.vue';
import TextButton from '@/components/TextButton.vue';
import Modal from '@/components/ModalComp.vue';
import Table from '@/components/TableComp.vue';
import TableRow from '@/components/TableRow.vue';
import TableHeaderItem from '@/components/TableHeaderItem.vue';
import TableColumn from '@/components/TableColumn.vue';
import Checkbox from '@/components/CheckboxComp.vue';
import InputGroup from '@/components/InputGroup.vue';
import { useTypesStore } from '@/stores/documentTypes';
import DatePicker from '@/components/DatePicker.vue';
import EmailModal from '@/components/EmailModal.vue';
import SearchHelp from '@/components/SearchHelp.vue';
import { useMagicKeys } from '@vueuse/core';
import FormattedError from '@/components/formattedError.vue';
import Pagination from '@/components/PaginationComp.vue';
import { useTimeoutsStore } from '@/stores/timeouts';

const timeoutStore = useTimeoutsStore();
const store = useDocumentsStore();
const typesStore = useTypesStore();
const axios: any = inject('axios');
const dateFromDatestring: any = inject('dateFromDatestring');
const formatNumber: any = inject('formatNumber');
const formatDate: any = inject('formatDate');
const router = useRouter();
const search = ref('');
const loading = ref(false);
const errorMessage = ref<Error & ErrorResponse | null>(null);
const showError = ref(false);
const searched = ref(false);
const loaded = ref(false);
let waitTimer: any;

const foundDocuments = ref<Document[] | []>([])

/**
 * Returns documents filtered from foundDocuments based on docType
 * @param {string} docType 
 */
const getDocTypeDocs = (docType: string) => {
  return foundDocuments.value.filter(doc => {
    return doc.documentTypeId === docType
  })
}

/**
 * Makes a request to /api/v1/documents/search/basic with a query
 * @param {string} prompt 
 * @param {string} typeId 
 * @param {boolean} reset 
 * @returns {Promise<{documentType: DocumentType, data: SearchResponse}>}
 */
const getTypeResults = (prompt: string, typeId: string, reset: boolean) => {
  return new Promise((resolve, reject) => {
    axios.get('/api/v1/documents/search/basic' + getRequestQuery(prompt, typeId, reset)).then((response: {data: SearchResponse}) => {
      let results = {documentType: typesStore.types.find(type => type.id === typeId), data: response.data}
      resolve(results)
    }).catch((error: Error) => {
      reject(error)
    })
  })
}

/**
 * Returns a query string
 * @param {string} prompt 
 * @param {string} typeId 
 * @param {boolean=} reset 
 * @returns {string}
 */
const getRequestQuery = (prompt: string, typeId: string, reset: boolean = false) => {
  let pagobj = paginationObjs.value.find(p => p.docTypeId === typeId)
  let page = pagobj ? pagobj.page : 1
  if (reset) {
    page = 1
  }

  let object: {
    freeText: boolean;
    perPage: number;
    documentTypeId: string;
    prompt?: string;
    amountFrom?: number;
    amountTo?: number;
    dateFrom?: string;
    dateTo?: string;
    page: number
  } = {
    freeText: false,
    perPage: numberOfResults.value,
    documentTypeId: typeId,
    page
  }

  prompt ? object.prompt = prompt : null;

  if (dateFrom.value) {
    object.dateFrom = dateFromDatestring(dateFrom.value)?.toISOString();
  }

  if (dateTo.value) {
   object.dateTo = dateFromDatestring(dateTo.value)?.toISOString();
  }

  if (amountFrom.value) {
    object.amountFrom = amountFrom.value;
  }

  if (amountTo.value) {
    object.amountTo = amountTo.value;
  }
  
  return '?' + new URLSearchParams(object as any).toString();
}

const paginationObjs = ref<{
  page: number;
  perPage: number;
  totalItems: number;
  docTypeId: string;
}[]>([])

/**
 * Changes the page of an object in paginationObjs and invokes getResultsForDocType
 * @param {string} id - The ID of a document type
 * @param {number} page 
 */
const handlePageChange = (id: string, page: number) => {
  if (paginationObjs.value.find(p => p.docTypeId === id)) {
    let pagobj = paginationObjs.value.find(p => p.docTypeId === id)
    if (pagobj) {
      pagobj.page = page
    }
  }
  getResultsForDocType(id)
}

/**
 * Adds an item to paginationObjs or overwrites it if it already exists
 * @param {{documentType: DocumentType, data: SearchResponse}} response 
 */
const handlePaginationObj = (response: {documentType: DocumentType, data: SearchResponse}) => {
  let paginationObj = {
    page: response.data.page,
    perPage: response.data.perPage,
    totalItems: response.data.totalItems,
    docTypeId: response.documentType.id
  }
  if (!paginationObjs.value.find(p => p.docTypeId === response.documentType.id)) {
    paginationObjs.value.push(paginationObj)
  } else {
    let pagobj = paginationObjs.value.find(p => p.docTypeId === response.documentType.id)
    if (pagobj) {
      pagobj.page = paginationObj.page
      pagobj.perPage = paginationObj.perPage
      pagobj.totalItems = paginationObj.totalItems
    }
  }
  store.paginationObjs = paginationObjs.value
}

const loadingDocType = ref<string | null>(null)

/**
 * Gets search resuklts for a specific document type
 * @param {string} id - The ID of a document type
 */
const getResultsForDocType = (id: string) => {
  store.results = store.results.filter(doc => doc.documentTypeId !== id)
  loadingDocType.value = id;
  getTypeResults(search.value, id, false).then((response: any) => {
    loadingDocType.value = null;
    handlePaginationObj(response)
    foundDocuments.value = foundDocuments.value.filter(d => d.documentTypeId !== id)
    for (let doc of (response.data.list as Document[])) {
      foundDocuments.value.push(doc as never)
      store.results.push(doc)
    }
  }).catch((error: Error & ErrorResponse) => {
    loadingDocType.value = null;
    errorMessage.value = error;
    showError.value = true;
  });
}

/**
 * Gets search results for all selected document types
 */
const getResults = () => {
  errorMessage.value = null;
  showError.value = false;
  store.results = []
  const timeouts = timeoutStore.addTimeouts({timeout: 5000}, {timeout: 10000}, 'hente søgeresultaterne', 'home')
  let promises = [];
  const activeElement = document.activeElement?.getAttribute('id');
  loading.value = true;
  searched.value = true;
  showError.value = false;

  for (let id of selectedTypes.value) {
    promises.push(getTypeResults(search.value, id, true))
  }

  Promise.all(promises).then((responses: any[]) => {
    timeoutStore.removeTimeouts(timeouts.ids)
    foundDocuments.value = [];
    loading.value = false;
    store.highlightedDocument = null;
    store.selectedDocuments = [];
    for (let response of responses) {
      handlePaginationObj(response)
      for (let doc of (response.data.list as Document[])) {
        foundDocuments.value.push(doc as never)
        store.results.push(doc)
      }
    }
    setTimeout(() => {
      setFocus(activeElement as string);
    }, 10);
  }).catch((error: Error & ErrorResponse) => {
    timeoutStore.removeTimeouts(timeouts.ids)
    errorMessage.value = error;
    showError.value = true
    loading.value = false;
    setTimeout(() => {
      setFocus(activeElement as string);
    }, 10);
  });
}

const typeOptions = computed(() => {
  let options = [];
  for (let type of [...typesStore.types].sort((a, b) => a.sortOrder - b.sortOrder)) {
    options.push({
      text: type.displayName,
      value: type.id
    })
  }
  return options;
})

const types = computed(() => {
  let types = [];
  for (let type of typesStore.types) {
    types.push(type.id)
  }
  return types
})

const selectedTypes = ref<string[]>([]);

/**
 * Selects all document types
 */
const selectAllTypes = () => {
  selectedTypes.value = JSON.parse(JSON.stringify(types.value))
}

/**
 * Deselects all document types
 */
const deselectAllTypes = () => {
  selectedTypes.value = []
}

watch(() => selectedTypes.value.length, (/*newVal*/) => {
  addQuery()
})

const gettingTypes = ref(true)
const typesTimeoutIds = ref<{ids: string[]} | null>(null)

onMounted(() => {
  if (store.query) {
    // if (store.query.prompt) search.value = store.query.prompt as string
    if (store.query.dateFrom) dateFrom.value = store.query.dateFrom as string
    if (store.query.dateTo) dateTo.value = store.query.dateTo as string
    if (store.query.amountFrom) amountFrom.value = store.query.amountFrom as number
    if (store.query.amountTo) amountTo.value = store.query.amountTo as number
    if (store.query.perPage) numberOfResults.value = parseInt(store.query.perPage as string)
    if (store.query.documentTypeIds) selectedTypes.value = [...store.query.documentTypeIds as string[]]
  }
  document.getElementById('mainsearch')?.focus();
  let query = router.currentRoute.value.query;
  typesTimeoutIds.value = timeoutStore.addTimeouts({}, {timeout: 10000}, 'hente dokumenttyperne', 'home', true)
  typesStore.getTypesFromApi(true).then(() => {
    if (typesTimeoutIds.value) {
      timeoutStore.removeTimeouts(typesTimeoutIds.value.ids)
    }
    if (!query.documentTypeIds && !selectedTypes.value.length) {
      let fakturaId = typesStore.types.find(type => type.slug === 'faktura')?.id
      if (fakturaId && !selectedTypes.value.includes(fakturaId)) selectedTypes.value.push(fakturaId)
    }
    gettingTypes.value = false;
  }).catch(() => {
    if (typesTimeoutIds.value) {
      timeoutStore.removeTimeouts(typesTimeoutIds.value.ids)
    }
    gettingTypes.value = false
  });
  getQuery()
  addQuery()
  if (store.results.length) {
    foundDocuments.value = store.results;
    if (store.paginationObjs.length) {
      paginationObjs.value = store.paginationObjs
    }
    setTimeout(() => {
      searched.value = true
    }, 1000);
  }
  else if (!store.results && search.value.length) {
    getResults();
  }
  if (store.highlightedDocument !== null) {
    setTimeout(() => {
      setFocus(`search-result-${store.highlightedDocument}`);
    }, 10);
  } else {
    setFocus('mainsearch');
  }
  
  setTimeout(() => {
    loaded.value = true;
  }, 250);
})

/**
 * Invokes addQuery and, after a timeout, invokes getResults
 * @param {number=} timeout - The length of the timeout. Defaults to 500 ms
 */
const handleFilter = (timeout: number = 500) => {
  validate().then(() => {
    clearTimeout(waitTimer);
    addQuery();
    waitTimer = setTimeout(() => {
      if (selectedTypes.value.length) {
        getResults();
      }
    }, timeout);
  }).catch(() => {})
}

const showHelp = ref(false);
const amountFrom = ref<number | null>(null);
const amountTo = ref<number | null>(null);
const dateFrom = ref('');
const dateTo = ref('');
const numberOfResults = ref(25);

const queryString = computed(() => {
  return {
    ...(search.value && { prompt: search.value }),
    ...(dateFrom.value && { dateFrom: dateFrom.value }),
    ...(dateTo.value && { dateTo: dateTo.value }),
    ...(amountFrom.value && { amountFrom: amountFrom.value }),
    ...(amountTo.value && { amountTo: amountTo.value }),
    ...(numberOfResults.value && numberOfResults.value !== 25 && {perPage: numberOfResults.value}),
    ...(selectedTypes.value.length && { documentTypeIds: selectedTypes.value })
  }
})

watch(() => [
  selectedTypes.value.length,
  dateFrom.value,
  dateTo.value,
  amountFrom.value,
  amountTo.value,
  numberOfResults.value
], ([
  newSelectedTypes,
  newDateFrom,
  newDateTo,
  newAmountFrom,
  newAmountTo,
  newNumberOfResults
], [
  oldSelectedTypes,
  oldDateFrom,
  oldDateTo,
  oldAmountFrom,
  oldAmountTo,
  oldNumberOfResults
]) => {
  if (newSelectedTypes !== oldSelectedTypes) {
    store.updateQuery({documentTypeIds: selectedTypes.value})
  }
  if (newDateFrom !== oldDateFrom) {
    store.updateQuery({dateFrom: newDateFrom})
  }
  if (newDateTo !== oldDateTo) {
    store.updateQuery({dateTo: newDateTo})
  }
  if (newAmountFrom !== oldAmountFrom) {
    store.updateQuery({amountFrom: newAmountFrom})
  }
  if (newAmountTo !== oldAmountTo) {
    store.updateQuery({amountTo: newAmountTo})
  }
  if (newNumberOfResults !== oldNumberOfResults) {
    store.updateQuery({perPage: newNumberOfResults})
  }
})

/**
 * Replaces the query in the router with the current value of queryString
 */
const addQuery = () => {
  router.replace({query: undefined});
  setTimeout(() => {
    router.replace({ query: queryString.value });
  }, 1);
}

/**
 * Gets the current routes query and adds its values to associated variables
 */
const getQuery = () => {
  let query = router.currentRoute.value.query;
  if (query.prompt) search.value = query.prompt as string;
  if (query.documentTypeIds) {
    if (typeof query.documentTypeIds === 'string') {
      selectedTypes.value = ([query.documentTypeIds] as string[]);
    } else {
      selectedTypes.value = (query.documentTypeIds as string[]);
    }
  }
  if (query.dateFrom) dateFrom.value = query.dateFrom as string;
  if (query.dateTo) dateTo.value = query.dateTo as string;
  if (query.amountFrom) amountFrom.value = parseFloat(query.amountFrom as string);
  if (query.amountTo) amountTo.value = parseFloat(query.amountTo as string);
  if (query.perPage) numberOfResults.value = parseInt(query.perPage as string);
}

/**
 * Invokes router.push to navigate to a document
 * @param {string} id - The ID of the document
 */
const goToDocument = (id: string) => {
  if (id) {
    router.push(`/document/${id}`);
  }
}

/** Sets focus when the down arrow is pressed */
const searchArrowDown = () => {
  if (store.highlightedDocument === null) {
    store.highlightedDocument = 0;
  } else if (store.highlightedDocument < foundDocuments.value.length - 1) {
    store.highlightedDocument += 1
  }
  setFocus(`search-result-${store.highlightedDocument}`);
}

/** Sets focus when the up arrow is pressed */
const searchArrowUp = () => {
  if (store.highlightedDocument === 0) {
    if (store.selectedDocuments.length) {
      setFocus('send-button');
    } else {
      setFocus('mainsearch');
    }
    store.highlightedDocument = null;
    return
  }
  if (store.highlightedDocument !== null && store.highlightedDocument !== 0) {
    store.highlightedDocument -= 1;
  } 
  setFocus(`search-result-${store.highlightedDocument}`);
}

/** Sets focus when the user presses arrow down while a document type is focused */
const arrowDownTypes = () => {
  const activeElement = document.activeElement?.getAttribute('id');
  const activeOption = typeOptions.value.find((o) => o.value === activeElement)
  const activeIndex = typeOptions.value.indexOf(activeOption);
  if (activeIndex === typeOptions.value.length - 1) {
    setFocus('select-all')
  } else {
    setFocus(typeOptions.value[activeIndex + 1].value)
  }
}

/** Sets focus when the user presses arrow up while a document type is focused */
const arrowUpTypes = () => {
  const activeElement = document.activeElement?.getAttribute('id');
  const activeOption = typeOptions.value.find((o) => o.value === activeElement)
  const activeIndex = typeOptions.value.indexOf(activeOption);
  if (activeIndex !== 0) {
    setFocus(typeOptions.value[activeIndex - 1].value)
  }
}

/** Sets focus when the user presses ctrl+alt+leftArrow in the main area */
const goToSidebar = () => {
  if (sidebarOpen.value) {
    setFocus('date-from');
  } else {
    setFocus('close-sidebar')
  }
}

/** Sets focus when the user presses ctrl+alt+rightArrow in the sidebar */
const goToMain = () => {
  if (store.highlightedDocument !== null) {
    setTimeout(() => {
      setFocus(`search-result-${store.highlightedDocument}`);
    }, 10);
  } else {
    setFocus('mainsearch');
  }
}

/**
 * Sets focus to a DOM element based on its ID
 * @param {string} id - The ID of a DOM element
 */
const setFocus = (id: string) => {
  document.getElementById(id)?.focus();
}

const emailModal = ref<{ showModal: boolean } | null>(null);

/** Opens the modal containing the send email form */
const openEmailModal = () => {
  if (emailModal.value) {
    emailModal.value.showModal = true;
  }
}

const sidebarOpen = ref(false)

/**
 * Sets sidebarOpen to the value of the param value
 * @param {boolean} value 
 */
const handleSidebar = (value: boolean) => {
  sidebarOpen.value = value
}

watch(() => sidebarOpen.value, (newVal) => {
  if (loaded.value) {
    if (newVal === true) {
      setTimeout(() => {
        setFocus('date-from')
      }, 250);
    } else if (newVal === false) {
      setFocus('mainsearch')
    }
  }
})

onBeforeUnmount(() => {
  //store.selectedDocuments = [];
})

onBeforeRouteLeave((to) => {
  if (to.name !== 'home' && to.name !== 'document') {
    store.results = [];
    store.paginationObjs = [];
  }
})

/**
 * Adds or subtracts 1 to/from numberOfResults based on whether + or - is pressed
 * @param {KeyboardEvent} e 
 */
const changeNumber = (e: KeyboardEvent) => {
  if (e.key === '+') {
    e.preventDefault();
    numberOfResults.value += 1;
  } else if (e.key === '-') {
    e.preventDefault();
    numberOfResults.value -= 1;
  }
}

/**
 * Sets dateFrom or dateTo to the value of dateString
 * @param {string} dateString 
 * @param {string} toOrFrom - The string 'from' or the string 'to'
 */
const handleDate = (dateString: string, toOrFrom: string) => {
  if (toOrFrom === 'from') {
    dateFrom.value = dateString;
  } else if (toOrFrom === 'to') {
    dateTo.value = dateString;
  }
  addQuery();
}

const { Ctrl_S, Ctrl_P } = useMagicKeys({
  passive: false,
  onEventFired(e) {
    if (e.ctrlKey && (e.key === 's' || e.key === 'p') && e.type === 'keydown') {
      e.preventDefault()
    }
  }
})

watch(Ctrl_S, (v) => {
  if (v) {
    if (store.selectedDocuments.length) {
      openEmailModal();
    }
  }
})

watch(Ctrl_P, (v) => {
  if (v) {
    if (store.selectedDocuments.length) {
      printAll()
    }
  }
})

/** Removes all iframes with the class print-frame that were generated by the printAll function */
const removePrintFrames = () => {
  document.querySelectorAll('.print-frame').forEach((frame) => {
    document.body.removeChild(frame)
  })
}

const printing = ref(false);

/** Calls /api/v1/documents/combine and creates a hidden iframe containing the results, and opens the print dialog */
const printAll = () => {
  removePrintFrames()
  errorMessage.value = null;
  showError.value = false
  if (store.selectedDocuments.length) {
    printing.value = true;
    let iframe: HTMLIFrameElement
    let ids = ''
    let first = true
    for (let id of store.selectedDocuments) {
      if (first) {
        ids += `?documentIds=${id}`
      } else {
        ids += `&documentIds=${id}`
      }
      first = false
    }
    axios.get(`/api/v1/documents/combine${ids}`, {
      responseType: 'blob'
    }).then((response: any) => {
      printing.value = false;
      const blob = response.data
      const objectUrl = URL.createObjectURL(blob);
      iframe = document.createElement('iframe');
      document.body.appendChild(iframe);

      iframe.style.display = 'none';
      iframe.classList.add('print-frame')
      iframe.src = objectUrl;
      iframe.onload = function() {
        setTimeout(function() {
          try {
            iframe.focus();
            iframe.contentWindow?.print();
          } catch (error: any) {
            printing.value = false;
            errorMessage.value = error
            showError.value = true;
          }
        }, 1);
      };
    }).catch(async (error: any) => {
      printing.value = false;
      let errorObj = await error.response.data.text();
      errorMessage.value = {
        ...error,
        response: {
          data: {
            ...JSON.parse(errorObj)
          }
        }
      }
      showError.value = true;
    })
  }
}

onBeforeUnmount(() => {
  removePrintFrames()
})

const mainSearchField = ref<null | { validate: Function }>(null);
const dateFromField = ref<null | { inputEl: any }>(null);
const dateToField = ref<null | { inputEl: any }>(null);
const amountFromField = ref<null | { validate: Function }>(null);
const amountToField = ref<null | { validate: Function }>(null);
const numberOfResultsField = ref<null | { validate: Function }>(null);

const valid = computed(() => {
  if (
    mainSearchField.value?.validate() &&
    dateFromField.value?.inputEl?.validate() &&
    dateToField.value?.inputEl?.validate() &&
    amountFromField.value?.validate() &&
    amountToField.value?.validate() &&
    numberOfResultsField.value?.validate()
  ) {
    return true
  }
  return false
})

/**
 * Validates the search and filter input fields
 * @returns {Promise<boolean>}
 */
const validate = () => {
  return new Promise((resolve, reject) => {
    if (
      mainSearchField.value?.validate() &&
      dateFromField.value?.inputEl?.validate() &&
      dateToField.value?.inputEl?.validate() &&
      amountFromField.value?.validate() &&
      amountToField.value?.validate() &&
      numberOfResultsField.value?.validate()
    ) {
      resolve(true)
    }
    reject(false)
  })
}

/**
 * Finds and returns the index of a specific document in foundDocuments
 * @param {string} id - The ID of a document object
 * @returns {number}
 */
const getIndex = (id: string) => {
  let doc = foundDocuments.value.find(d => d.id === id)
  // @ts-ignore
  return foundDocuments.value.indexOf(doc)
}
</script>

<template>
  <Modal card-id="help" title="Hjælp til søgning" show-close-button no-max-width v-model="showHelp" :error="null" cardClass="max-w-[800px]">
    <SearchHelp></SearchHelp>
  </Modal>
  <EmailModal ref="emailModal" id="email-modal"></EmailModal>
  <PageWrap sidebar @toggle-sidebar="(e) => handleSidebar(e)">
    <h1 class="hidden">Søgning</h1>
    <!-- Sidebar start -->
    <template v-slot:sidebar>
      <form autocomplete="off" @submit.prevent>
        <SidebarGroup id="document-types-group" title="Dokumenttype" @keyup.right.alt.ctrl="() => goToMain()">
          <Alert
            type="loading"
            :show="gettingTypes"
            sm
            class="mb-2 !max-w-full !w-full"
          >
            Henter dokumenttyper
            <TransitionGroup name="fade">
              <div
                v-for="id of typesTimeoutIds?.ids"
                :key="id"
                v-show="timeoutStore.getShowTimeout(id)"
              >
                <Alert
                  :type="timeoutStore.getTimeoutType(id) || 'info'"
                  :show="timeoutStore.getShowTimeout(id) || false"
                  class="doctypes-timeout-message mt-2 !max-w-full !w-full"
                  sm
                >
                  {{ timeoutStore.getTimeoutMessage(id) }}
                </Alert>
              </div>
            </TransitionGroup>
          </Alert>
          <Alert
            type="error"
            :show="!selectedTypes.length && !gettingTypes && types.length > 0"
            sm
            class="doctype-alert mb-2 !max-w-full !w-full"
          >Du skal vælge mindst én dokumenttype</Alert>
          <Alert
            type="error"
            :show="!gettingTypes && loaded && types.length === 0"
            sm
            class="mb-2 !max-w-full !w-full"
          >Kunne ikke hente dokumenttyper</Alert>
          <CheckboxGroup
            id="document-types-checkboxes"
            :options="typeOptions"
            v-model="selectedTypes"
            :disabled="loading"
            @keyup.down="() => arrowDownTypes()"
            @keyup.up="() => arrowUpTypes()"
            @keyup.enter="() => handleFilter(0)"
            tabindex="7"
          ></CheckboxGroup>
          <div class="flex gap-4 mt-2">
            <Button
              sm
              color="primary"
              class="grow"
              id="select-all"
              @click="() => selectAllTypes()"
              :disabled="loading"
              @keyup.right="() => setFocus('deselect-all')"
              @keyup.down="() => setFocus('date-from')"
              @keyup.up="() => setFocus(typeOptions[types.length - 1].value)"
              tabindex="8"
            >Vælg alle</Button>
            <Button
              sm
              color="primary"
              class="grow"
              id="deselect-all"
              @click="() => deselectAllTypes()"
              :disabled="loading"
              @keyup.left="() => setFocus('select-all')"
              @keyup.down="() => setFocus('date-from')"
              @keyup.up="() => setFocus(typeOptions[types.length - 1].value)"
              tabindex="9"
            >Fravælg alle</Button>
          </div>
        </SidebarGroup>
        <SidebarGroup id="filtering-group" title="Filtrering" @keyup.right.alt.ctrl="() => goToMain()">
          <InputGroup label="Fra dato">
            <DatePicker
              v-model="dateFrom"
              :disabled="loading"
              id="date-from"
              placeholder="DD-MM-ÅÅÅÅ eller DDMMÅÅÅÅ"
              @keyup.enter="() => handleFilter(0)"
              @keyup.up="() => setFocus('deselect-all')"
              @keyup.down="() => setFocus('date-to')"
              @select="(e: string) => handleDate(e, 'from')"
              tabindex="2"
              ref="dateFromField"
            ></DatePicker>
          </InputGroup>
          <InputGroup class="mt-4" label="Til dato">
            <DatePicker
              v-model="dateTo"
              :disabled="loading"
              id="date-to"
              placeholder="DD-MM-ÅÅÅÅ eller DDMMÅÅÅÅ"
              @keyup.enter="() => handleFilter(0)"
              @keyup.up="() => setFocus('date-from')"
              @keyup.down="() => setFocus('amount-from')"
              @select="(e: string) => handleDate(e, 'to')"
              tabindex="3"
              ref="dateToField"
            ></DatePicker>
          </InputGroup>
          <InputGroup class="mt-4" label="Beløb fra">
            <Input
              v-model="amountFrom"
              placeholder="Eks.: 2100"
              type="number"
              @keyup.enter="() => handleFilter(0)"
              id="amount-from"
              @keyup.up="() => setFocus('date-to')"
              @keyup.down="() => setFocus('amount-to')"
              @keydown.down.prevent
              @keydown.up.prevent
              :disabled="loading"
              step="100"
              @update:model-value="() => addQuery()"
              @reset="() => amountFrom = null"
              show-reset
              tabindex="4"
              pattern="^(|-{0,1}[0-9]+[,.]{0,1}[0-9]{0,2})$"
              ref="amountFromField"
              message="Indtast venligst et hel- eller decimaltal, med maks. 2 decimaler"
            ></Input>
          </InputGroup>
          <InputGroup class="mt-4" label="Beløb til">
            <Input
              v-model="amountTo"
              placeholder="Eks.: 2100"
              type="number"
              @keyup.enter="() => handleFilter(0)"
              id="amount-to"
              @keyup.up="() => setFocus('amount-from')"
              @keyup.down="() => setFocus('number-of-results')"
              @keydown.down.prevent
              @keydown.up.prevent
              :disabled="loading"
              step="100"
              @update:model-value="() => addQuery()"
              @reset="() => amountTo = null"
              show-reset
              tabindex="5"
              pattern="^(|-{0,1}[0-9]+[,.]{0,1}[0-9]{0,2})$"
              ref="amountToField"
              message="Indtast venligst et hel- eller decimaltal, med maks. 2 decimaler"
              :min="`${amountFrom}`"
            ></Input>
          </InputGroup>
          <InputGroup class="mt-4" label="Resultater per side (maks. 100)">
            <Input
              type="number"
              v-model="numberOfResults"
              placeholder=""
              @keyup.enter="() => handleFilter(0)"
              id="number-of-results"
              @keyup.up.prevent="() => setFocus('amount-to')"
              @keydown="(e: KeyboardEvent) => changeNumber(e)"
              @keydown.down.prevent
              @keydown.up.prevent
              :disabled="loading"
              @update:model-value="() => addQuery()"
              min="1"
              max="100"
              tabindex="6"
              ref="numberOfResultsField"
              pattern="^(|[0-9]+)$"
              message="Indtast venligst et heltal mellem 1 og 100"
            ></Input>
          </InputGroup>
        </SidebarGroup>
      </form>
    </template>
    <!-- Sidebar end -->
    <!-- Print and send buttons start -->
    <div class="relative 2xl:absolute z-0 mb-4 right-0 w-full flex gap-2 justify-end">
      <Button
        sm
        color="primary"
        :disabled="!store.selectedDocuments.length"
        id="print-button"
        @keyup.left.prevent="() => setFocus('mainsearch')"
        @keyup.right.prevent="() => setFocus('send-button')"
        @keyup.down.prevent="() => searchArrowDown()"
        @keydown.down.prevent
        tabindex="10"
        title="Ctrl + P"
        @click="() => printAll()"
        :loading="printing"
      ><u>P</u>rint valgte</Button>
      <Button 
        sm
        color="primary"
        :disabled="!store.selectedDocuments.length"
        @click="() => openEmailModal()"
        id="send-button"
        @keyup.left.prevent="() => setFocus('print-button')"
        @keyup.down.prevent="() => searchArrowDown()"
        @keydown.down.prevent
        tabindex="11"
        title="Ctrl + S"
      ><u>S</u>end valgte</Button>
    </div>
    <!-- Print and send buttons end -->
    <!-- Main search start -->
    <div class="w-[600px] max-w-full mx-auto relative z-10">
      <form autocomplete="off" @submit.prevent>
        <div class="flex gap-2">
          <Input
            id="mainsearch"
            v-model="search"
            class="grow"
            input-class="py-3 px-4 sm:text-xl"
            type="search"
            placeholder="Søg efter dokumenter"
            icon="search"
            large-icon
            @keyup.enter="() => handleFilter(0)"
            @keyup.left.alt.ctrl="() => goToSidebar()"
            @keydown.down.prevent="() => searchArrowDown()"
            :disabled="loading"
            @focus="store.highlightedDocument = null"
            @input-focus="store.highlightedDocument = null"
            tabindex="1"
            pattern="^[a-zA-Z0-9øæåÆØÅ \x22\x27\x2D_.,]*$"
            ref="mainSearchField"
            messageClass="absolute mt-2"
            show-reset
            @reset="() => search = ''"
            clear-button-class="!right-10 top-4"
          ></Input>
          <Button
            :disabled="!selectedTypes.length || !valid"
            id="search-button"
            @click="() => handleFilter(0)"
            color="primary"
            tabindex="-1"
            @keydown.down.prevent="() => searchArrowDown()"  
          >Søg</Button>
        </div>
        <TextButton class="float-right mt-2" sm @click="() => showHelp = true" tabindex="-1">Hjælp til søgning?</TextButton>
      </form>
    </div>
    <!-- Main search end -->
    <!-- Results start -->
    <div class="mt-16">
      <div class="alerts relative">
        <div class="relative text-center">
          <Alert
            id="error-message"
            type="error"
            :show="showError"
            class="left-1/2 -translate-x-1/2 absolute -top-8 z-30 text-left"
          >
            <FormattedError :error="errorMessage" entity="søgeresultaterne"></FormattedError>
          </Alert>
          <Alert
            type="loading"
            :show="loading"
            class="left-1/2 -translate-x-1/2 absolute -top-8 z-30"
          >Søger</Alert>
          <Alert
            id="info-message"
            type="info"
            :show="!loading && !foundDocuments.length && !showError && searched"
            class="left-1/2 -translate-x-1/2 absolute -top-8 z-30"
            auto-hide
            @close="() => searched = false"
          >Ingen resultater fundet</Alert>
        </div>
        <template v-for="(docType, index) of typesStore.types" :key="index">
          <template v-if="getDocTypeDocs(docType.id).length">
            <div class="flex gap-4 items-baseline">
              <h2 class="text-secondary">{{ docType.displayName }}</h2>
              <!-- <span class="text-secondary/50 italic">{{ getDocTypeDocs(docType.id).length }} resultat(er)</span> -->
            </div>
            <template v-for="(pagination, index) of paginationObjs">
              <Pagination
                v-if="pagination.docTypeId === docType.id"
                class="mb-4"
                :key="index"
                :page="pagination.page"
                :perPage="pagination.perPage"
                :total="pagination.totalItems"
                @changePage="(e) => handlePageChange(docType.id, e)"
                text-bottom
              ></Pagination>
            </template>
            <Table :id="`${docType.slug}-table`" class="mt-2 mb-4" :error="null" :disabled="loading || loadingDocType === docType.id" :disabled-spinner="true" @keydown.left.alt.ctrl="() => goToSidebar()">
              <template v-slot:headers>
                <TableHeaderItem
                  v-for="(item, index) of getDocTypeDocs(docType.id)[0].fields.sort((a, b) => a.sortOrder - b.sortOrder)"
                  :key="index"
                  :class="item.slug === 'beloeb' || item.slug === 'dato' ? 'text-right' : ''"
                >
                  {{ item.displayName }}
                </TableHeaderItem>
                <TableHeaderItem class="w-0"></TableHeaderItem>
              </template>
              <TableRow
                v-for="(document) of getDocTypeDocs(docType.id)"
                :key="document.id"
                tabindex="1"
                :id="`search-result-${ getIndex(document.id) }`"
                @keydown.up.prevent="() => searchArrowUp()"
                @keydown.down.prevent="() => searchArrowDown()"
                @keydown.space.prevent="() => store.toggleDocumentSelected(document.id)"
                @click="() => goToDocument(document.id)"
              >
                <TableColumn
                  v-for="field of document.fields.sort((a, b) => a.sortOrder - b.sortOrder)"
                  :key="field.slug"
                  :class="[
                    field.slug === 'beloeb' ? 'text-right amount-field' : '',
                    field.slug === 'dato' ? 'text-right date-field' : ''
                  ]"
                  :data-amount="field.slug === 'beloeb' ? field.value : null"
                  :data-date="field.slug === 'dato' ? field.value : null"
                >
                  <template v-if="field.slug === 'dato'">
                    {{ formatDate(field.value as string) }}
                  </template>
                  <template v-else-if="field.slug === 'beloeb'">
                    {{ formatNumber(field.value as number) }}
                  </template>
                  <template v-else>
                    {{ field.value }}
                  </template>
                </TableColumn>
                <TableColumn not-empty>
                  <Checkbox
                    value=""
                    no-label
                    tabindex="-1"
                    :checked="store.selectedDocuments.includes(document.id)"
                    @click.stop="store.toggleDocumentSelected(document.id)"
                  ></Checkbox>
                </TableColumn>
              </TableRow>
            </Table>
            <template v-for="(pagination, index) of paginationObjs">
              <Pagination
                v-if="pagination.docTypeId === docType.id"
                class="mb-5"
                :key="index"
                :page="pagination.page"
                :perPage="pagination.perPage"
                :total="pagination.totalItems"
                @changePage="(e) => handlePageChange(docType.id, e)"
              ></Pagination>
            </template>
          </template>
        </template>
      </div>
    </div>
    <!-- Results start -->
  </PageWrap>
</template>

<style>
.preview em {
  @apply text-primary/50 font-bold;
}
</style>