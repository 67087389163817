<script setup lang="ts">
import { ref, watch } from 'vue';

defineProps<{
  title: string;
}>();

const collapsed = ref(false)

/** Toggles collapsed between true and false */
const toggleCollapse = () => {
  collapsed.value = !collapsed.value;
}
const content = ref<HTMLElement | null>(null)
watch(() => collapsed.value, (newVal) => {
  if (!newVal) {
    setTimeout(() => {
      content.value && content.value.classList.add('collapsed')
    }, 250);
  } else {
    content.value && content.value.classList.remove('collapsed')
  }
})
</script>

<template>
  <div class="!mb-4">
    <div class="flex gap-4 cursor-pointer hover:bg-gray p-2 transition rounded-t" @click="() => toggleCollapse()">
      <p v-if="title" class="text-primary font-bold text-sm grow">{{ title }}</p>
      <span class="icon-chevron-down-sm block text-secondary-lighter transition origin-center w-4 h-4" :class="collapsed && 'rotate-90'"></span>
    </div>
    <hr v-if="title" class="mb-2" />
    <div class="sidebar-collapser" :class="!collapsed && 'shown'">
      <div class="collapse-content collapsed" ref="content">
        <slot></slot>
        <hr class="mt-2" />
      </div>
    </div>
  </div>
</template>

<style>
.sidebar-collapser {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows .25s;
}

.sidebar-collapser > .collapse-content {
  overflow: hidden;
  opacity: 0;
  transition: opacity .25s;
}

.sidebar-collapser.shown {
  grid-template-rows: 1fr;
}

.sidebar-collapser.shown > .collapse-content {
  opacity: 1;
}

.collapse-content.collapsed {
  overflow: visible !important;
}
</style>