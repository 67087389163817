<script lang="ts" setup>
import Modal from './ModalComp.vue';
import InputGroup from './InputGroup.vue';
import Input from './InputComp.vue';
import Button from './ButtonComp.vue';
import TextArea from './TextArea.vue';
import { ref, watch } from 'vue';
import { useEmailStore } from '@/stores/email';
import { useDocumentsStore } from '@/stores/documents';
import axios from 'axios';
import { useTimeoutsStore } from '@/stores/timeouts';
import Alert from './AlertComp.vue';

const timeoutStore = useTimeoutsStore();
const store = useEmailStore();
const documentStore = useDocumentsStore();
const showModal = ref(false);
const recipient = ref('');
const subject = ref('')
const message = ref('');
const recipientInput = ref<{ inputRef: HTMLElement; validate: Function; shake: Function } | null>(null);
const subjectInput = ref<{ validate: Function; shake: Function } | null>(null);
const messageInput = ref<{ validate: Function; shake: Function } | null>(null);

defineExpose({
  showModal
});

const entityName = ref('e-mailen');
const actionName = ref('sende');
const errorMessage = ref(null);
const loadingTemplate = ref(false);
const templateLoaded = ref(false);

/** gets the email template by invoking getEmailTemplateFromApi in the email store */
const loadTemplate = () => {
  if (!templateLoaded.value) {
    actionName.value = 'hente'
    entityName.value = 'e-mailskabelonen'
    loadingTemplate.value = true;
    const timeouts = timeoutStore.addTimeouts({}, { timeout: 10000 }, 'hente e-mailskabelonen', 'other')
    store.getEmailTemplateFromApi().then((response: any) => {
      loadingTemplate.value = false
      timeoutStore.removeTimeouts(timeouts.ids)
      subject.value = response.subject;
      message.value = response.message;
      store.subject = response.subject;
      store.message = response.message;
    }).catch((error) => {
      loadingTemplate.value = false
      timeoutStore.removeTimeouts(timeouts.ids)
      errorMessage.value = error
    })
  }
}

const sending = ref(false)
 /**
  * Validates the recipient, subject and message fields
  * @returns {boolean}
  */
const validate = () => {
  if (recipientInput.value?.validate() && subjectInput.value?.validate() && messageInput.value?.validate()) {
    return true
  }
  recipientInput.value?.shake();
  subjectInput.value?.shake();
  messageInput.value?.shake();
  return false
}

/** Sends a POST request to /api/v1/mail/send */
const sendEmail = () => {
  if (validate()) {
    actionName.value = 'sende'
    entityName.value = 'e-mailen'
    const timeouts = timeoutStore.addTimeouts({}, { timeout: 10000 }, 'sende e-mailen', 'other')
    errorMessage.value = null
    sending.value = true
    axios.post('/api/v1/mail/send', {
      email: recipient.value,
      subject: subject.value,
      body: message.value,
      documentIds: documentStore.selectedDocuments
    }).then(() => {
      timeoutStore.removeTimeouts(timeouts.ids)
      recipient.value = '';
      showModal.value = false;
      sending.value = false;
    }).catch((error) => {
      timeoutStore.removeTimeouts(timeouts.ids)
      errorMessage.value = error
      sending.value = false;
    })
  }
}

watch(() => showModal.value, () => {
  if (showModal.value === true) {
    loadTemplate()
    setTimeout(() => {
      recipientInput.value?.inputRef.focus();
    }, 10);
  }
})
</script>

<template>
  <Modal
    title="Send dokumenter som e-mail"
    show-close-button
    no-max-width
    :error="errorMessage"
    v-model="showModal"
    cardClass="w-[500px]"
    :disabled="sending"
    :error-entity="entityName"
    :timeout-action="actionName"
  >
    <Alert type="loading-info" :show="loadingTemplate" sm class="mb-4">Henter e-mailskabelonen</Alert>
    <InputGroup label="E-mail" class="mb-4">
      <Input
        v-model="recipient"
        ref="recipientInput"
        placeholder="Indtast modtagers e-mailadresse"
        @keyup.enter="() => sendEmail()"
        pattern="^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$"
        :max-length="64"
        required
        id="recipient-input"
      ></Input>
    </InputGroup>
    <InputGroup label="Emne" class="mb-4 relative">
      <Input
      v-model="subject"
      ref="subjectInput"
      placeholder="Indtast et emne for e-mailen"
      pattern="(.|\s)+"
      :max-length="64"
      required
      :disabled="loadingTemplate"
      ></Input>
      <span
        class="text-xs text-primary block absolute top-2 right-0 cursor-pointer"
        v-if="subject !== store.subject"
        @click="() => subject = store.subject"
      >Gendan</span>
    </InputGroup>
    <InputGroup label="Besked" class="mb-4 relative">
      <TextArea
        v-model="message"
        ref="messageInput"
        :rows="5"
        pattern="(.|\s)+"
        required
        :min-length="8"
        :max-length="512"
        :disabled="loadingTemplate"
      ></TextArea>
      <span
        class="text-xs text-primary block absolute top-2 right-0 cursor-pointer"
        v-if="message !== store.message"
        @click="() => message = store.message"
      >Gendan</span>
    </InputGroup>
    <div class="flex justify-end">
      <Button
        sm
        color="primary"
        @click="() => sendEmail()"
        :disabled="loadingTemplate"
        id="send-email-button"
      >Send</Button>
    </div>
  </Modal>
</template>