
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import axios from 'axios'
import VueAxios from 'vue-axios'

import App from './App.vue'
import router from './router'

import './assets/main.css'
import "./assets/ok-icons/style.css";

import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./authConfig";
import { type AuthenticationResult, EventType } from "@azure/msal-browser";
import { CustomNavigationClient } from "./router/NavigationClient";

// try {
  // The next 2 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
  const navigationClient = new CustomNavigationClient(router);
  msalInstance.setNavigationClient(navigationClient);

  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
// } catch (error) {
//   console.log('hej', error)
// }

const app = createApp(App)

/**
 * Formats a number to match the style of DKK currency
 * @param {number} num 
 */
const formatNumber = (num: number) => {
  if (num) {
    const newNum = num.toLocaleString('da-DK', { style: 'currency', currency: 'DKK' });
    return newNum;
  }
} 
app.provide('formatNumber', formatNumber)

/**
 * Formats a datestring to match DD-MM-YYYY (e.g. 28-02-2024)
 * @param {string} date 
 * @param {boolean=} withTime
 * @returns {string}
 */
const formatDate = (date: string, withTime: boolean = false) => {
  if (date) {
    const datestring = new Date(date).toLocaleDateString('da-DK', { year: 'numeric', month: 'numeric', day: 'numeric' });
    const digits = datestring.split('.');
    let newString = ''
    for (let digit of digits) {
      if (digit.length < 3) {
        digit = ('0' + digit).slice(-2)
        newString += digit + '-'
      } else {
        newString += digit
      }
    }

    if (withTime) {
      newString += ` ${new Date(date).toLocaleTimeString('da-DK', {hour: '2-digit'})}`
      newString += `:${('0' + new Date(date).toLocaleTimeString('da-DK', {minute: '2-digit'})).slice(-2)}`
    }

    return newString
  }
}
app.provide('formatDate', formatDate)

/**
 * Creates a date from the dateString param
 * @param {string} datestring - string with the format 00-00-0000 or 00000000
 * @returns {Date}
 */
const dateFromDatestring = (datestring: string) => {
  if (datestring.includes('-') && datestring.length === 10) {
    const parts = [] as number[];
    const substrings = datestring.split('-');
    for (const string of substrings) {
      parts.push(parseInt(string))
    }
    const date = new Date();
    date.setDate(parts[0]);
    date.setMonth(parts[1] - 1);
    date.setFullYear(parts[2]);
    return date;
  } else if (!datestring.includes('-') && datestring.length === 8) {
    const parts = [
      parseInt(datestring.substring(0,2)),
      parseInt(datestring.substring(2,4)),
      parseInt(datestring.substring(4,8))
    ] as number[];
    const date = new Date();
    date.setDate(parts[0]);
    date.setMonth(parts[1] - 1);
    date.setFullYear(parts[2]);
    return date;
  }
}
app.provide('dateFromDatestring', dateFromDatestring)

/**
 * Makes a string lower case, replaces spaces with underscore (_) and replaces 'æ', 'ø' and 'å' with 'ae', 'oe' and 'aa'
 * @param {string} string 
 * @returns {string}
 */
const slugify = (string: string) => {
  return string.trim().toLowerCase().replace(/æ/g, 'ae').replace(/ø/g, 'oe').replace(/å/g, 'aa').replace(/[^A-Z0-9]+/ig, "_")
}
app.provide('slugify', slugify)

app.use(createPinia())
app.use(router)
app.use(VueAxios, axios)
app.use(msalPlugin, msalInstance);

app.provide('axios', app.config.globalProperties.axios);

app.directive("empty", {
  mounted: (el: HTMLElement, binding) => {
    if (binding.value) {
      const string = el.innerText;
      if (!string) {
        el.classList.add('italic');
        el.classList.add('text-secondary/50');
        el.innerText = 'Ingen værdi';
      }
    }
  },
  updated: (el: HTMLElement, binding) => {
    if (binding.value) {
      const string = el.innerText;
      if (!string) {
        el.classList.add('italic');
        el.classList.add('text-secondary/50');
        el.innerText = 'Ingen værdi';
      }
    }
  },
});

//router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount('#app');
//})
