<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import Sidebar from './SidebarComp.vue';
import TextButton from './TextButton.vue';
import Overlay from './OverlayComp.vue';

defineProps<{
  sidebar?: boolean;
  width?: number;
}>()

const emit = defineEmits<{
  (e: 'toggle-sidebar', payload: boolean): void
}>();

const sidebarOpen = ref(true);

/**
 * Sets sidebarOpen to true or false
 * @param {boolean} value
 * @fires togle-sidebar
 */
const setSidebar = (value: boolean) => {
  sidebarOpen.value = value;
  emit('toggle-sidebar', value);
};

const breakpoint = ref('')

onMounted(() => {
  window.addEventListener('resize', () => {
    getBreakpoint()
  })
  getBreakpoint()
})

const breakpoints = ref(['xs', 'sm', 'md', 'lg', 'xl', '2xl'])

watch(() => breakpoint.value, (newValue, oldValue) => {
  let newIndex = breakpoints.value.indexOf(newValue);
  let oldIndex = breakpoints.value.indexOf(oldValue);
  if (oldIndex !== -1) {
    if ((breakpoint.value === 'sm' || breakpoint.value === 'xs') && oldIndex > newIndex) {
      setSidebar(false)
    } else if ((breakpoint.value === 'md' || breakpoint.value === 'lg' || breakpoint.value === 'xl' || breakpoint.value === '2xl') && oldIndex < newIndex) {
      setSidebar(true)
    }
  } else {
    if (breakpoint.value === 'sm' || breakpoint.value === 'xs') {
      setSidebar(false)
    } else if (breakpoint.value === 'md' || breakpoint.value === 'lg' || breakpoint.value === 'xl' || breakpoint.value === '2xl') {
      setSidebar(true)
    }
  }
})

/** Sets breakpoint based on current window width */
const getBreakpoint = () => {
  let width = window.innerWidth;
  let newBreakpoint = 'xs';
  if (width >= 640) {
    newBreakpoint = 'sm';
  }
  if (width >= 768) {
    newBreakpoint = 'md';
  }
  if (width >= 1024) {
    newBreakpoint = 'lg';
  }
  if (width >= 1280) {
    newBreakpoint = 'xl';
  }
  if (width >= 1536) {
    newBreakpoint = '2xl';
  }
  breakpoint.value = newBreakpoint;
}
</script>

<template>
  <Sidebar
    v-if="sidebar"
    :sidebar-open="sidebarOpen"
    @toggle-sidebar="(e: boolean) => setSidebar(e)"
    :disabled="!sidebarOpen"
  >
    <slot name="sidebar"></slot>
  </Sidebar>
  <div v-if="sidebar" class="fixed left-0 top-[64px] w-[40px] bottom-0 z-[1] bg-white pt-2 text-center border-r">
    <TextButton @click="() => setSidebar(true)" :disabled="sidebarOpen" tabindex="-1" id="close-sidebar">
      <span class="icon-sidebar text-[24px]"></span>
    </TextButton>
  </div>
  <Overlay :show="sidebarOpen" class="block sm:hidden z-[1]" @click="() => setSidebar(false)"></Overlay>
  <main
    class="transition-all px-4 relative"
    :class="sidebar ? sidebarOpen ? 'ml-[40px] sm:ml-[300px]' : 'ml-[40px]' : 'ml-0'"
  >
  <div class="mx-auto relative" :class="width ? '' :  'max-w-[1440px]'" :style="width ? `max-width: ${width}px;` : ''">
    <slot></slot>
  </div>
  </main>
</template>./SidebarComp.vue./OverlayComp.vue