import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useTimeoutsStore = defineStore('timeout', () => {
  const timeouts = ref<{id: string; message?: string; type?: string; show?: boolean; timeout: any, route: string; local?: boolean}[]>([]);
  const timeoutMessage = ref('Der kom ikke et svar fra serveren.\nPrøv venligst at genindlæse siden, eller kontakt din administrator, hvis problemet fortsætter.')
  const errorHeaders = {
    'x-test-mode-delay': 2,
    'x-test-mode-status': 404,
    // 'x-test-mode-error-code': 2
  }

  /**
   * Returns the message used when API requests takes longer than usual
   * @param {string} action 
   * @returns {string}
   */
  const getStandardMessage = (action: string) => {
    return `Det tager længere tid, end forventet, at ${action}.\nGiv os et øjeblik, eller prøv at genindlæse siden, hvis problemet fortsætter.`
  }

  /**
   * Returns the second message used when API requests take even longer than usual
   * @param {string} action 
   * @returns {string}
   */
  const getSecondMessage = (action: string) => {
    return `Der er måske problemer med, at ${action}.\nPrøv at genindlæse siden, eller kontakt din administrator, hvis problemet fortsætter.`
  }

  /**
   * Gets the message used when API requests time out
   * @param {string|undefined} [action=undefined]
   * @returns {string}
   */
  const getTimeoutErrorMessage = (action: string | undefined = undefined) => {
    if (action) {
      return `Kunne ikke ${action}. ${timeoutMessage.value}`
    }

    return timeoutMessage.value
  }

  /**
   * Generates an ID
   * @returns {string}
   */
  const generateId = () => {
    return Math.random().toString(16).slice(2)
  }

  /**
   * Adds a timeout object to timeouts variable
   * @param {string} id 
   * @param {string} message 
   * @param {string} [type=info] 
   * @param {number} [amount=2000] 
   * @param {string|undefined} [idToRemove=undefined] 
   * @param {string} route 
   * @param {boolean} local 
   */
  const addTimeout = (id: string, message: string, type: string = 'info', amount: number = 2000, idToRemove: string | undefined = undefined, route: string, local: boolean) => {
    const t = {
      id,
      type,
      route,
      message,
      show: false,
      timeout: null,
      local
    } as {id: string; timeout: any; type: string; route: string;}
    
    t.timeout = setTimeout(() => {
      if (idToRemove) {
        const timeoutToRemove = timeouts.value.find(t => t.id === idToRemove)
        if (timeoutToRemove) {
          timeoutToRemove.show = false
        }
      }
      const timeout = timeouts.value.find(t => t.id === id)
      if (timeout) {
        timeout.show = true
      }
    }, amount);

    timeouts.value.push(t)
  }

  /**
   * Invokes addTimeout to add first and second timeout objects for a specific action in a specific route
   * @param {Object} first 
   * @param {number=} first.timeout
   * @param {Object} second 
   * @param {number=} second.timeout
   * @param {string} action 
   * @param {string} route 
   * @param {boolean} [local=false]
   * @returns {{ids: string[]}}
   */
  const addTimeouts = (first: {timeout?: number}, second: {timeout?: number}, action: string, route: string, local: boolean = false) => {
    const ids = [generateId(), generateId()]
    addTimeout(ids[0], getStandardMessage(action), 'info', first.timeout ? first.timeout : undefined, undefined, route, local);
    addTimeout(ids[1], getSecondMessage(action), 'warning', second.timeout ? second.timeout : undefined, ids[0], route, local);
    return { ids }
  }

  /**
   * Clears the timeout function of a specific timeout object
   * @param {string} id 
   */
  const removeTimeout = (id: string) => {
    const timeout = timeouts.value.find(t => t.id === id)
    if (timeout) {
      clearTimeout(timeout.timeout)
      timeouts.value = timeouts.value.filter(t => t.id !== id)
    }
  }

  /**
   * Invokes removeTimeou for each id in ids
   * @param {string[]} ids 
   */
  const removeTimeouts = (ids: string[]) => {
    for (const id of ids) {
      removeTimeout(id)
    }
  }

  /**
   * Invokes removeTimeout for all timeout objects associated with a specific route
   * @param {string} route 
   */
  const removeAllTimeouts = (route: string) => {
    if (route) {
      for (const timeout of timeouts.value.filter(t => t.route === route)) {
        removeTimeout(timeout.id)
      }
    }
  }

  /**
   * Finds and returns a specific timeout object
   * @param {string} id 
   * @returns {*} - A timeout object
   */
  const getTimeout = (id: string) => {
    if (timeouts.value.find(t => t.id === id)) {
      return timeouts.value.find(t => t.id === id)
    }
  }

  /**
   * Returns the show attribute of a timeout object
   * @param {string} id 
   * @returns {boolean}
   */
  const getShowTimeout = (id: string) => {
    if (getTimeout(id)) {
      return getTimeout(id)?.show
    }
    return false
  }

  /**
   * Returns the message attribute of a timeout object
   * @param {string} id 
   * @returns {string}
   */
  const getTimeoutMessage = (id: string) => {
    if (getTimeout(id)) {
      return getTimeout(id)?.message
    }
    return ''
  }

  /**
   * Returns the type attribute of a timeout object
   * @param {string} id 
   * @returns {string}
   */
  const getTimeoutType = (id: string) => {
    if (getTimeout(id)) {
      return getTimeout(id)?.type
    }
    return ''
  }

  return {
    timeouts,
    timeoutMessage,
    errorHeaders,
    getStandardMessage,
    getSecondMessage,
    getTimeoutErrorMessage,
    addTimeout,
    addTimeouts,
    removeTimeout,
    removeTimeouts,
    removeAllTimeouts,
    getTimeout,
    getShowTimeout,
    getTimeoutMessage,
    getTimeoutType
  }
})
