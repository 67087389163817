<script lang="ts" setup>
import Input from './InputComp.vue';
import TextButton from './TextButton.vue';
import PopUp from './PopUp.vue';
import Calendar from './CalendarComp.vue';
import { CalendarIcon } from '@heroicons/vue/20/solid';
import { onMounted, ref, watch } from 'vue';
import moment from 'moment';

const props = defineProps<{
  modelValue: string;
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  tabindex?: string;
  pattern?: string;
}>();

const showPopup = ref(false);
const localValue = ref<string>('');
const inputEl = ref<{ inputRef: HTMLElement, validate: Function } | null>(null);

const emit = defineEmits<{
  (e: 'select', payload: string): void;
  (e: "update:modelValue", payload: string): void;
  (e: "keyup", payload: KeyboardEvent): void;
}>();

onMounted(() => {
  localValue.value === props.modelValue;
});

watch(() => props.modelValue, (newVal) => {
  localValue.value = newVal;
});

watch(() => localValue.value, (newVal) => {
  if (validate(newVal) || newVal === '') {
    emit('update:modelValue', newVal);
    emit('select', newVal)
  }
})

const validDate = ref(true)

/**
 * Validates the value of the field
 * @param {string} value 
 * @returns {boolean}
 */
const validate = (value: string) => {
  if (value) {
    validDate.value = validateDate(value)
    return validDate.value
  }
  return false
}

/**
 * Validates that the dateString can be converted into a correct date object
 * @param dateString 
 * @returns {boolean}
 */
const validateDate = (dateString: string) => {
    if (!new RegExp(/^$|^\d{2}-*\d{2}-*\d{4}$/).test(dateString)) return false
    const parsedDate = moment(dateString, "DD-MM-YYYY")
    return parsedDate.isValid()
}

/**
 * Is invoked when a date is selcted in the calendar component
 * @param {string} value 
 * @fires select
 */
const dateSelect = (value: string) => {
  emit('select', value);
  showPopup.value = false;
  inputEl.value?.inputRef.focus();
}

defineExpose({
  inputEl
});
</script>

<template>
  <div class="relative">
    <Input
      v-model="localValue"
      :placeholder="placeholder"
      :id="id"
      :disabled="disabled"
      @keyup="(e: KeyboardEvent) => emit('keyup', e)"
      ref="inputEl"
      :pattern="pattern"
      custom-validation
      :customValidationValue="validDate"
      custom-validation-message="Indtast venligst en gyldig værdi. DDMMÅÅÅÅ eller DD-MM-ÅÅÅÅ"
      message="Indtast venligst en gyldig værdi. DDMMÅÅÅÅ eller DD-MM-ÅÅÅÅ"
      show-reset
      @reset="() => localValue = ''"
      :tabindex="tabindex"
      input-class="pr-10"
    ></Input>
    <TextButton
      color="primary"
      id="date-to-button"
      class="absolute right-1.5 top-1.5"
      @click="showPopup = true"
      tabindex="-1"
    >
      <CalendarIcon class="w-6" />
    </TextButton>
  </div>
  <PopUp
    parent-id="date-to-button"
    v-model:show="showPopup"
    class="p-2 rounded-xl"
  >
    <Calendar
      :selected="validate(localValue) ? localValue : ''"
      show-next
      show-prev
      @date-select="(e: string) => dateSelect(e)"
    />
  </PopUp>
</template>./InputComp.vue./CalendarComp.vue