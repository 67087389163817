import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { Document, SearchResponse } from '@/types'
import axios from 'axios'

export const useDocumentsStore = defineStore('documents', () => {
  const documents = ref<Document[]>([]);
  const results = ref<Document[]>([]);
  const paginationObjs = ref<any[]>([]);
  const advancedResults = ref<SearchResponse | null>(null);
  const selectedDocuments = ref<string[]>([]);
  const highlightedDocument = ref<number | null>(null);
  const query = ref<{[key: string]: string | number | string[]}>({})

  /**
   * Updates the stored query
   * @param {Object.<string, string|number|string[]>} value 
   */
  const updateQuery = (value: {[key: string]: string | number | string[]}) => {
    query.value = {...query.value, ...value}
  }

  /**
   * Adds a document to documents array
   * @param {Document} document 
   */
  const addDocument = (document: Document) => {
    if (documents.value.find(doc => doc.id === document.id)) {
      documents.value[documents.value.findIndex((i) => i.id === document.id)] = document;
    } else {
      documents.value.push(document)
    }
  }

  /**
   * Gets a specific document from the API
   * @param {string} id 
   * @returns {Promise}
   */
  const getDocumentFileFromApi = (id: string) => {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/documents/${id}`).then(response => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    })
    
  }

  /**
   * Gets a specific document from the store
   * @param {string} id 
   * @returns {Promise<Document>}
   */
  const getDocumentFromStore = (id: string) => {
    return new Promise((resolve, reject) => {
      if (documents.value.find(doc => doc.id === id)) {
        resolve(documents.value.find(doc => doc.id === id))
      } else {
        reject()
      }
    })
  }

  /**
   * Adds/removes a document ID from selectedDocuments
   * @param {string} id 
   */
  const toggleDocumentSelected = (id: string) => {
    const i = selectedDocuments.value.indexOf(id)
    if (i === -1) {
      selectedDocuments.value.push(id)
    } else {
      selectedDocuments.value.splice(i, 1);
    }
  }

  return { 
    documents,
    results,
    paginationObjs,
    advancedResults,
    selectedDocuments,
    query,
    highlightedDocument,
    updateQuery,
    addDocument,
    getDocumentFileFromApi,
    getDocumentFromStore,
    toggleDocumentSelected
  }
})
